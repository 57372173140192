import React from "react";
import "./App.css";
import Homepage from "./pages/homepage/homepage";
import "./styles.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Services from "./pages/services/services";
import Gallery from "./pages/gallery/gallery";
import Contact from "./pages/contact/contact";
import About from "./pages/about/about";
import { Routes, Route } from "react-router-dom";

const App = () => (
  <>
    <Header />
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
    <Footer />
  </>
);

export default App;
