import Gallery1 from "../../images/gallery-1.png";
import Gallery2 from "../../images/gallery-2.png";
import Gallery3 from "../../images/gallery-3.png";
import Gallery4 from "../../images/gallery-4.png";
import Gallery5 from "../../images/gallery-5.jpg";
import Gallery6 from "../../images/gallery-6.jpg";
import Gallery7 from "../../images/gallery-7.jpg";
import Gallery8 from "../../images/gallery-8.jpeg";
import Gallery9 from "../../images/gallery-9.jpeg";
import Gallery10 from "../../images/gallery-10.jpeg";
import Gallery11 from "../../images/gallery-11.jpeg";
import Gallery12 from "../../images/gallery-12.jpeg";
import Gallery13 from "../../images/gallery-13.jpeg";
import Gallery14 from "../../images/gallery-14.jpeg";
import Gallery15 from "../../images/gallery-15.jpeg";
import Gallery16 from "../../images/gallery-16.jpeg";
import Gallery17 from "../../images/gallery-17.jpeg";

const SliderData = [
  { image: Gallery8 },
  { image: Gallery9 },
  { image: Gallery10 },
  { image: Gallery11 },
  { image: Gallery12 },
  { image: Gallery13 },
  { image: Gallery14 },
  { image: Gallery15 },
  { image: Gallery16 },
  { image: Gallery17 },
  { image: Gallery1 },
  { image: Gallery2 },
  { image: Gallery3 },
  { image: Gallery4 },
  { image: Gallery5 },
  { image: Gallery6 },
  { image: Gallery7 },
];

export default SliderData;
